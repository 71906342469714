
/* ==== CSS VARIABLES ==== */
:root {
  --primary-color: #335eea;
  --link-color: #506690;
  --btn-hover-color: #2b50c7;
  --lg-heading: #161c2d;
  --text-content: #869ab8;
  --fixed-header-height: 4.5rem;
}

ul li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
/* ==== CONTAINER ==== */
.container {
  width: 100%;
}
@media screen and (min-width: 1040px) {
  .container {
    width: 1040px;
    margin: 0 auto;
  }
}
/* ==== HEADER ==== */
.header {
  height: var(--fixed-header-height);
  padding: 0 1.7rem;
}

/* ==== NAV ==== */
.nav {
  margin-top: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* ==== LOGO ==== */
.logo h2 {
  font-size: 28px;
  color: var(--primary-color);
}
/* ====  NAV-MENU  ==== */
.nav_menu_list {
  display: flex;
  align-items: center;
}
.nav_menu_list .nav_menu_item {
  margin: 0 2rem;
}
.nav_menu_item .nav_menu_link {
  font-size: 16.5px;
  line-height: 27px;
  /*color: var(--link-color);*/
  color: white;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
#navHello{
  font-size: 16.5px;
  line-height: 27px;
  /*color: var(--link-color);*/
  color: white;
  letter-spacing: 0.5px;

}
.nav_menu_link:hover {
  color: var(--primary-color);
}
.toggle_btn {
  font-size: 20px;
  font-weight: 600;
  color: var(--lg-heading);
  z-index: 4;
}
.nav_menu,
.close_btn {
  display: none;
}
.show {
  right: 3% !important;
}


/* ==== MEDIA QURIES FOR RESPONSIVE DESIGN ==== */
@media screen and (min-width: 768px) {
  .toggle_btn {
    display: none;
  }
  .nav_menu {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .logo h2 {
    font-size: 23px;
  }
  .nav_menu {
    position: fixed;
    width: 93%;
    height: 100%;
    display: block;
    top: 2.5%;
    right: -100%;
    background-color: #fff;
    padding: 3rem;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
    z-index: 50;
    transition: 0.4s;
  }
  .nav_menu_list {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 4rem;
  }
  .nav_menu_list .nav_menu_item {
    margin: 1rem 0;
  }
  .nav_menu_item .nav_menu_link {
    font-size: 18px;
  }
  .close_btn {
    display: block;
    position: absolute;
    right: 10%;
    font-size: 25px;
    color: #50689e;
  }
  .close_btn:hover {
    color: #000;
  }
}

@media screen and (max-width: 700px) {
  #menu__toggle {
    opacity: 0;
  }
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    left: 0 !important;
  }



  .menu__btn {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 91;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #616161;
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #f2f2f2;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
    z-index: 90;
  }
  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
  }
  .menu__item:hover {
    background-color: #CFD8DC;
  }
}